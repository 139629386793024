import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import './styles/App.scss';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Home, WIP, NotFound } from './components/App.js';
import { Contactus } from './components/Contact.js';
import { Blog } from "./components/Blog.js";
import NavBar from "./components/NavBar.js";

class App extends Component {
  render () {
    return (
      <BrowserRouter basename={'/'}>
        <Switch>
          <Route exact path='/' component={ Home } />
          <Route exact path='/blog' component={ Blog } />
          <Route exact path='/contactus' component={ Contactus } />
          <Route exact path='/workinprogress' component={ WIP } />
          <Route component={NotFound} />
        </Switch>
        <NavBar />
      </BrowserRouter>
    );
  };
};



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
