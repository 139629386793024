import React, { Component } from 'react';
import LandingSlider from './Slick';
import logo from '../styles/perspectives_logo_white.svg';

class Home extends Component {
  render () {
    return (
      <div className="App">
        <div className="App-landing-container">
          <div className="App-W-container">
            <img className="App-logo" src={logo} alt="Waite Perspectives Logo"/>
            <h1 className="App-logo-txt">
              <span className="App-logo-txt heavy">WAITE </span>
              PERSPECTIVES, LLC
            </h1>
          </div>
        </div>
        <LandingSlider />
      </div>
    );
  };
};

class NotFound extends Component {
  render () {
    return (
      <div className="App">
        <div className="App-landing-container">
          <div className="App-W-container">
            <img className="App-logo" src={logo} alt="Waite Perspectives Logo"/>
            <h1 className="App-logo-txt">
              Page Not Found...
            </h1>
         </div>
        </div>
      </div>
    );
  };
};

class WIP extends Component {
  render () {
    return (
      <div className="App">
        <div className="App-landing-container">
          <div className="App-W-container">
            <img className="App-logo" src={logo} alt="Waite Perspectives Logo"/>
            <h1 className="App-logo-txt">
              Work In Progress ...
            </h1>
          </div>
        </div>
      </div>
    );
  };
};


export { Home, WIP, NotFound };
