import React from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';


class LandingSlider extends React.Component {

  render () {

    const settings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    return (
      <Slider {...settings}>
        <div>
          <h4>See All the Angles ... </h4>
          <Link to="/contactus" className="enter-btn" >Discover How</Link>
        </div>
        <div>
          <h4>The Cure for Manualitis ... </h4>
          <Link to="/contactus" className="enter-btn" href="/test">Get Cured</Link>
        </div>
        <div>
          <h4>Paperless is Not a Myth ... </h4>
          <Link to="/contactus" className="enter-btn" href="/test">Break Free</Link>
        </div>
      </Slider>
    );
  };
};

export default LandingSlider;

