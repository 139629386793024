import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Menu, MenuItem, MenuItemLink, MenuItemArrow } from '@progress/kendo-react-layout';

const linkRender = (props) => {
    return (
        <MenuItemLink url={props.item.url} opened={props.opened}>
            {[
                renderMenuIconIfApplicable(props),
                props.item.text,
                renderArrowIfApplicable(props)
            ]}
        </MenuItemLink>
    );
};

function renderMenuIconIfApplicable(props) {
    return props.item.icon ? <img src={props.item.icon} className="Navbar-nav-icons" alt="icon" role="presentation" key="0" /> : null;
}

function renderArrowIfApplicable(props) {
    return props.item.items && props.item.items.length > 0 ?
        <MenuItemArrow itemId={props.itemId} verticalMenu={false} dir={props.dir} key="1" /> : null;
}


class NavBar extends Component {

  onSelect = (event) => {
    this.props.history.push(event.item.data.route);
  }

  render () {
    return (
      <div className="Navbar shadow-bottom">
        <div className="nav-container">
          <Menu onSelect={this.onSelect}>
            <MenuItem text=""
             icon={"assets/home.svg"}
             data={{ route: '/' }}
             linkRender={linkRender}
            />
            <MenuItem text=""
             icon={"assets/publications.svg"}
             data={{ route: '/blog' }}
             linkRender={linkRender}
            />
            <MenuItem text=""
             icon={"assets/contactus.svg"}
             data={{ route: '/contactus' }}
             linkRender={linkRender}
            />
          </Menu>
        </div>
      </div>
    )
  };
};

export default withRouter(NavBar);
