import React, { Component } from "react";
import { blogs } from "../content/blogs.js";

const BlogPost = (props) => {
  return (
      <div className="blog-post">
        <div className="shadow-bottom flex-row">
          <h1>{ props.title }</h1>
          <h4>{ props.date }</h4>
        </div>
        <div className="">
          <p dangerouslySetInnerHTML={{__html: props.body }}></p>
          <p>{ props.signature }</p>
        </div>
      </div>
  );
}

export class Blog extends Component {
    render() {
        return (
          <div className="App App-bg-dark">
            <div className="App-landing-container"
             style={{ display: `flex`, justifyContent: `center` }}>
             <div className="App-blog-container">
               {blogs.map((b, i) => {
                 return (
                   <BlogPost
                    key={`blog-${i}`}
                    { ...b}
                    />
                 )
               })}
             </div>
            </div>
          </div>
        );
    }
}
