import React, { Component } from "react";
import logo from "../styles/perspectives_logo_white.svg";

export class Contactus extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-landing-container">
          <div className="App-W-container">
            <img
              className="App-logo"
              src={logo}
              alt="Waite Perspectives Logo"
            />
            <div className="App-logo-headline">
              <h1 className="App-logo-txt primary">Zachary M. Waite</h1>
              <h3 className="App-logo-txt secondary">Independent Consultant</h3>
            </div>
            <ul className="App-services-list">
              <li>
                Odoo Consulting
                <span className="pipe-spaced">|</span>
              </li>
              <li>
                Event Driven Systems
                <span className="pipe-spaced">|</span>
              </li>
              <li>
                Data Migrations
                <span className="pipe-spaced">|</span>
              </li>
              <li>
                Connectors
              </li>
            </ul>
            <h4 className="App-contact-info">
              <span className="contact-label">phone:</span>
              <a className="email-link" href="tel:(518) 232-4900">
                (518) 232-4900
              </a>
            </h4>
            <h4 className="App-contact-info">
              <span className="contact-label">email:</span>
              <a
                className="email-link"
                href="mailto:zach@waiteperspectives.com"
              >
                zach@waiteperspectives.com
              </a>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}
