export const blogs = [
  {
    title: "4 Pillars of Quality",
    date: "February 5, 2021",
    signature: `
      That's my perspective.
    `,
    body:`
    
    <ol class="inline-list">
      <li>Desired and undesired behaviors must be documented as requirements.</li>
      <li>Requirements must be versioned in tandem with source code, build artifacts and test results.</li>
      <li>Requirements must be tested, and results recorded, before each delivery.</li>
      <li>Requirements must be monitored, and results recorded, after delivery.</li>
    </ol>
    
    FAQ:
    <br/>
    <br/>
    <strong>Q:&nbsp;</strong> Why?
    <br/>
    <strong>A:&nbsp;</strong> Following these practices will increase accountability in all stakeholders,
    help quantify work efforts, reveal complexity that would be otherwise missed and provide living documentation
    for your systems.
    <br/>
    <br/>
    <strong>Q:&nbsp;</strong> Do all requirements have to be defined before development?
    <br/>
    <strong>A:&nbsp;</strong> Not necessarily, that might be a waste. However, it is also not the opposite extreme. When
    in doubt, be explicit.
    The idea is to gradually grow the detail of the requirements as new features are requested with each version.
    Keeping versions small and atomic is a best practice.
    <br/>
    <br/>
    <strong>Q:&nbsp;</strong> What happens when requirements change?
    <br/>
    <strong>A:&nbsp;</strong> Work needs to be done to analyze the implications of the change throughout the system.
    The discipline of documenting and versioning the requirements makes this process explicit and the appropriate parties accountable.
    If requirements are organized to mirror the code, and the code is properly decoupled, this should be as simple as replacing a slice of requirements/source code.
    <br/>
    <br/>
    <strong>Q:&nbsp;</strong> What project management tool should I use for this?
    <br/>
    <strong>A:&nbsp;</strong> Businesses don't care about epics, user stories, kanbans or tasks and
    how you can organize them into expanding lists or burndown charts. Frankly, if it can't hook into your code, it's
    almost pointless. Specification by example using version controlled files is ideal. BDD feature files are best
    because there are well established ways to automatically verify the status of the requirement. That said, any tools
    that can help with the process of keeping the requirements in sync with the tests and code are useful.
    <a href="https://eventmodeling.org/">Event Modeling</a> is a great way to capture requirements visually,
    just don't forget the feature files.
    <br/>
    <br/>
    <strong>Q:&nbsp;</strong>Writing all these requirements sounds time consuming, it's easier to just make a
    code change and move on!
    <br/>
    <strong>A:&nbsp;</strong>Well, changing or adding a requirement is a signal to change the behavior of the system.
    This has implications and should be recorded and versioned to ensure accountability and documentation of your system. 
    `
  },
  {
    title: "Measurements",
    date: "January 1, 2021",
    signature: `
      Happy new year!
    `,
    body:`
      Software development never seems to be measured well. Why? Every other industry seems to have figured it out.
      I think a big reason is that it's so hard to measure the process, let alone define metrics for measuring the
      quality of said process. This year, I'm devoting significant effort to building a foundation for measuring 
      both productivity and quality, so I can reduce project risk for all stakeholders. Look forward to future
      updates about improvements to requirements specification and design, better auditability of systems,
      squashing bugs before they happen with functional programming, and fixed price projects.
    `
  },
  {
    title: "Kendo UI Added to Offering",
    date: "July 5, 2020",
    signature: `
     Stay tuned for more updates.
    `,
    body:`
    I'm pleased to announce that I recently licensed the excellent
    <a href="https://www.telerik.com/kendo-ui">Kendo UI</a>
    javascript library. I plan to use Kendo React Components to accelerate my
    growing front-end offering and Kendo UI for jQuery to customize Odoo front-end
    apps, websites and customer portals.
    `
  },
  {
    title: "Perspectives on blogging",
    date: "July 4, 2020",
    signature: `
      That's my perspective.
    `,
    body:`
     Generally I prefer to express intent and importance via something tangible,
     such as a code commit or delivery of an application. However, I do concede
     that these things don't always provide sufficient visibility and are not always
     appropriately scoped to satisfy my needs for business communications. Additionally, 
     I've found there is some merit to
     <a href="https://www.swyx.io/writing/learn-in-public/">
       learning in public
     </a> as it provides a certain accountability when working independently.
     Beyond this blog, I have an idea to post some quantitative metrics about
     my work without exposing the details, primarily from git analytics, and
     a roadmap of the work that I plan to be doing in the near future. In a nutshell,
     I'm currently considering my website and this blog a useful tool that should be
     leveraged.
    `
  }
];
